import { render, staticRenderFns } from "./TableHeader.vue?vue&type=template&id=4689dc32&scoped=true"
import script from "./TableHeader.vue?vue&type=script&lang=js"
export * from "./TableHeader.vue?vue&type=script&lang=js"
import style0 from "./TableHeader.vue?vue&type=style&index=0&id=4689dc32&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../school_end/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4689dc32",
  null
  
)

export default component.exports